<template>
  <section>
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active">
          <a href="#tab_1" data-toggle="tab"
            ><i class="fa fa-info-circle"></i> {{ $t("identification") }}</a
          >
        </li>
        <li>
          <a href="#tab_2" data-toggle="tab">
            <i class="fa fa-tags"></i>
            {{ $t("tags") }}</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="tab_1">
          <div class="row">
            <div :class="nameColClasses">
              <div
                class="form-group"
                :class="{'has-error': $attrs.errors && $attrs.errors.name}"
              >
                <label for="item_name" class="noselect">
                  {{ $t("name") }}
                  <ToolTip :title="$t('hints.name', {item: $tc(entity, 1)})" />
                </label>
                <input
                  type="text"
                  name="item_name"
                  class="form-control"
                  data-testid="name"
                  required
                  v-bind:disabled="isModelBased || $attrs.disabled"
                  @keydown="onKeyDown"
                  @contextmenu.prevent.stop
                  v-model="name"
                  ref="inpname"
                  autocomplete="off"
                  :title="$attrs.errors && $t($attrs.errors.name)"
                />
              </div>
            </div>
            <div :class="enabledSmallColClasses" class="text-center">
              <label class="noselect">{{ $tc("enabled") }}?</label>
              <div>
                <FormInputToggle
                  v-bind:labels="$t('yes') + ',' + $t('no')"
                  colors="#119056,#a0a0a0"
                  fontSize="18"
                  v-model="item_enabled"
                ></FormInputToggle>
                <!-- :disabled="$attrs.disabled" -->
              </div>
              <!-- <div class="hide-lg hide-md" style="margin-bottom:20px;" /> -->
            </div>
            <div :class="descriptionColClasses">
              <div class="form-group">
                <label for="item_description" class="noselect">
                  {{ $t("description") }}
                  <ToolTip
                    :title="$t('hints.description', {item: $tc(entity, 1)})"
                  />
                </label>
                <input
                  type="text"
                  name="item_description"
                  class="form-control"
                  data-testid="description"
                  v-bind:disabled="isModelBased || $attrs.disabled"
                  @keydown="onKeyDown"
                  @contextmenu.prevent.stop
                  v-model="description"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12" v-if="entity == 'data'">
              <div class="form-group">
                <label for="item_identity_embedded_app" class="noselect">
                  {{ $t("titles.identity_embedded_app") }}
                  <ToolTip :title="$t('hints.identity_embedded_app')" />
                </label>
                <input
                  type="text"
                  name="item_identity_embedded_app"
                  class="form-control"
                  data-testid="identity_embedded_app"
                  v-bind:disabled="isModelBased || disabledIdentityEmbeddedApp"
                  @keydown="onKeyDown"
                  @contextmenu.prevent.stop
                  v-model="identity_embedded_app"
                  autocomplete="off"
                />
              </div>
            </div>
            <div :class="enabledMiddleColClasses" class="text-center">
              <label class="noselect">{{ $tc("enabled") }}?</label>
              <div>
                <FormInputToggle
                  v-bind:labels="$t('yes') + ',' + $t('no')"
                  colors="#119056,#a0a0a0"
                  fontSize="18"
                  v-model="item_enabled"
                />
                <!-- :disabled="$attrs.disabled" -->
              </div>
              <!-- <div class="hide-lg hide-md" style="margin-bottom:20px;" /> -->
            </div>
          </div>
        </div>

        <div class="tab-pane" id="tab_2">
          <div class="row">
            <div class="col-md-6">
              <TagsForm v-model="tags" />
            </div>
            <div class="col-md-6">
              <ResourceField :value="value" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import FormInputToggle from "@/components/registration/form-input-toggle";
import messages from "@/i18n/connector";
import TagsForm from "@/components/registration/tags-form.vue";
import ResourceField from "@/components/registration/resource-field.vue";
import {inputStringParser} from "@/plugins/utils.js";
const strParser = inputStringParser; // TODO: temporary solution since there are dependencies on the function name
const onKeyDown = ($e) => {
  // console.log(`|${$e.target.value}|`);
  if (($e.key == "v" || $e.key == "V") && $e.ctrlKey) {
    const t = $e.target;
    setTimeout(() => {
      t.value = strParser(t.value);
      t.dispatchEvent(new Event("input"), {bubbles: true});
    }, 5);
    return;
  }
  /['&@"<>,;]/.test($e.key) && $e.preventDefault();
};
export {strParser, onKeyDown};
export default {
  name: "FormCommonFieldsInline",
  components: {
    ToolTip,
    FormInputToggle,
    TagsForm,
    ResourceField
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: "",
          description: "",
          identity_embedded_app: "",
          enabled: true
        };
      }
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      previous_name: "",
      iName: "",
      iDescription: "",
      iIdentity: ""
    };
  },
  computed: {
    item_enabled: {
      get() {
        return this.value.enabled ? "1" : "0";
      },
      set(value) {
        this.$emit("input", {
          ...(this.value || {}),
          enabled: value == "1" ? true : false
        });
      }
    },
    tags: {
      set(value) {
        this.value.portal_data = this.value.portal_data || {};
        this.$set(this.value.portal_data, "tags", value);
        this.$emit("input", this.value);

        // var payload = { ...this.value || {} };
        // payload.portal_data = { ...payload.portal_data || {} };
        // payload.portal_data.tags = { ...value };
        // this.$emit("input", payload);
      },
      get() {
        return this?.value?.portal_data?.tags || [];
      }
    },
    name: {
      set(value) {
        this.iName = value;
        this.$emit("input", {...(this.value || {}), name: strParser(value)});
      },
      get() {
        return this.iName;
      }
    },
    description: {
      set(value) {
        this.iDescription = value;
        this.$emit("input", {
          ...(this.value || {}),
          description: strParser(value)
        });
      },
      get() {
        return this.iDescription;
      }
    },
    identity_embedded_app: {
      set(value) {
        this.iIdentity = value;
        this.$emit("input", {
          ...(this.value || {}),
          identity_embedded_app: strParser(value)
        });
      },
      get() {
        return this.iIdentity;
      }
    },
    info() {
      return (
        this.previous_name &&
        this.previous_name != this.item_name &&
        ("connector" in this.value || "device" in this.value)
      );
    },
    nameColClasses() {
      if (this.entity == "data") {
        return "col-md-3 col-sm-9 col-xs-8";
      } else {
        return "col-md-5 col-sm-4 col-xs-8";
      }
    },
    descriptionColClasses() {
      if (this.entity == "data") {
        return "col-md-4 col-sm-12 col-xs-12";
      } else {
        return "col-md-5 col-sm-5 col-xs-12";
      }
    },
    enabledSmallColClasses() {
      if (this.entity == "data") {
        return "hidden-lg hidden-md col-sm-3 col-xs-4";
      } else {
        return "hidden-lg hidden-md hidden-sm col-xs-4";
      }
    },
    enabledMiddleColClasses() {
      if (this.entity == "data") {
        return "col-md-2 hidden-sm hidden-xs";
      } else {
        return "col-md-2 col-sm-3 hidden-xs";
      }
    },
    entity() {
      if (this.value?.data) return "alarm";
      else if (this.value?.device) return "data";
      else if (this.value?.connector) return "device";
      else return "connector";
    },
    disabledIdentityEmbeddedApp() {
      return (
        this.disabled &&
        this.isModelBased &&
        !this.value?.device?.reference_device_id
      );
    }
  },
  i18n: {
    messages
  },
  methods: {
    onKeyDown: onKeyDown
  },
  mounted() {
    this.previous_name = this?.value?.name || "";
    this.iName = this?.value?.name || "";
    this.iDescription = this?.value?.description || "";
    this.iIdentity = this?.value?.identity_embedded_app || "";
    if (this?.$refs?.inpname) {
      this.$refs.inpname.focus();
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.nav > li > a {
  padding: 5px 5px 10px 5px;
  min-width: 60px;
  text-align: center;
  text-transform: uppercase;
}

div.nav-tabs-custom > ul > li.active > a {
  /* color: #337ab7; */
  font-weight: 600;
}
</style>
