<template>
  <section>
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="isEmpty && !discarded.length"
    >
      <EmptyListAlert
        buttonLabel="add_new_data"
        importLabel="import_data"
        :hideImport="!$can('manage', 'DadoCadastro') || hideImport()"
        :isInstance="connector && connector.base_model_id != null"
        @import="importData()"
        :newItemPath="newItemPath"
        :disabled="isModelBased"
      />
    </div>
    <template v-else>
      <div
        class="tab-pane active"
        id="edit"
        role="tabpanel"
        aria-labelledby=""
        v-if="connector"
      >
        <SearchableTable
          v-if="list && !busy"
          class="searchable-table"
          :tableClass="tableClass"
          :items="filteredItems"
          :fields="fields"
          :commands="commands"
          :multiColumnOrder="false"
          :clientSort="true"
          :pagination="pagination"
          :maxResult="nTotal"
          :deepSearch="false"
          :multiSelection="multiSelection"
          :disabled="cloneEnabled"
          :sortDef="sortDef"
          @select="onSelect"
          @command="onCommand"
          @multiColumnSort="onMultiColumnSort"
          @nItems="maxResult = $event"
          @currentSort="currentSort = $event"
          ref="stbl"
        >
          <template v-slot:label="entry">
            <MQTTTopicSpan
              :entry="entry.item"
              @active="activeTopic = entry.item.id"
              :active="activeTopic == entry.item.id"
            />
          </template>
          <template #customFilters>
            <div class="row">
              <div class="form-group col-md-6 custom-filters">
                <label
                  class="filter-option noselect"
                  v-for="item in inlineFilter"
                  :key="item.name"
                  @click="item.onclick(item)"
                >
                  <template
                    v-if="
                      item.name != 'only_deleted' ||
                        (item.name == 'only_deleted' &&
                          !connector.base_model_id)
                    "
                  >
                    <input type="checkbox" v-model="item.value" />
                    <span>
                      {{ $t(item.title) }}
                      <Spin
                        v-if="
                          item.name == 'only_deleted' && fetchingDeletedItems
                        "
                        :inline="true"
                        divClasses=""
                        sizeClass=""
                      ></Spin>
                    </span>
                  </template>
                </label>
              </div>
            </div>
          </template>
          <template #extraButtons>
            <button
              id="export"
              type="button"
              class="btn btn-default"
              :disabled="list && list.length == 0"
              @click.stop.prevent="downloadCSV(connector)"
              :title="$t('hints.button_export')"
            >
              <i class="fa fa-file-excel-o" />
            </button>
            <button
              v-if="multiSelection.key"
              id="mass_restore"
              type="button"
              class="btn btn-default"
              :disabled="!massRestoreEnabled"
              @click="massRestore"
              :title="$t('mass_restore')"
            >
              <i class="fa fa-undo" />
            </button>
            <button
              v-if="multiSelection.key"
              id="mass_remove"
              type="button"
              class="btn btn-default"
              :disabled="!massRemoveEnabled"
              @click="massRemove"
              :title="$t('mass_remove')"
            >
              <i class="fa fa-trash-o" />
            </button>
          </template>
          <template #statistics>
            <ResourceStatistics
              resource="data"
              :total="nTotal"
              :showing="nShowing"
              icon="glyphicon glyphicon-stats"
            />
          </template>
          <template #paginationBefore>
            <div v-if="msgBoard" class="alert alert-default msg-board">
              {{ msgBoard }}
            </div>
          </template>

          <!-- BEGIN editable columns -->

          <!-- begin draft_name -->
          <template v-slot:draft_name="entry" v-if="!cloneEnabled && editable">
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(entry.item, 'name')
                }"
              >
                <input
                  type="text"
                  class="form-control text-left"
                  :value="draftValue(entry.item, 'name')"
                  :title="
                    entry.item?.identity_embedded_app
                      ? `${$t('titles.identity_embedded_app')}: ${
                          entry.item?.identity_embedded_app
                        }`
                      : ''
                  "
                  @input="draftValue(entry.item, 'name', $event)"
                  @keydown="onKeyDown"
                  @contextmenu.prevent.stop
                  :disabled="!draftEnabled(entry.item, 'name')"
                />
              </div>
            </div>
          </template>
          <!-- end draft_name -->

          <!-- begin draft_label -->
          <template v-slot:draft_label="entry" v-if="!cloneEnabled && editable">
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(entry.item, 'label')
                }"
              >
                <input
                  v-if="entry.item.is_local"
                  type="text"
                  class="form-control"
                  :value="$t('not_available')"
                  :disabled="true"
                />
                <div class="input-group" v-else>
                  <input
                    type="text"
                    class="form-control text-left"
                    :value="draftValue(entry.item, 'label')"
                    @input="draftValue(entry.item, 'label', $event)"
                    :disabled="!draftEnabled(entry.item, 'label')"
                  />
                  <MQTTTopicSpan
                    class="input-group-addon btn"
                    :entry="draftItem(entry.item)"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- end draft_label -->

          <!-- begin draft_source_value -->
          <template
            v-slot:draft_source_value="entry"
            v-if="!cloneEnabled && editable"
          >
            <div class="column-content" @click.stop>
              <div class="form-group form-group-sm">
                <template v-if="entry.item.is_local">
                  <input
                    disabled
                    type="number"
                    class="form-control disabled no-arrows"
                    :value="$t('local_data')"
                    style="padding-left: 5px"
                  />
                </template>
                <template v-else>
                  <select
                    v-if="!busy"
                    class="form-control"
                    :value="draftValue(entry.item, 'source_value')"
                    @input="draftValue(entry.item, 'source_value', $event)"
                    :disabled="!draftEnabled(entry.item, 'source_value')"
                  >
                    <option
                      :value="o.id"
                      :key="o.id"
                      v-for="o in dataValueSourceTypesFiltered(entry.item)"
                    >
                      {{ o.name }}
                    </option>
                  </select>
                </template>
              </div>
            </div>
          </template>
          <!-- end draft_source_value -->

          <!-- begin draft_memory_address -->
          <template
            v-slot:draft_memory_address="entry"
            v-if="!cloneEnabled && editable"
          >
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(entry.item, 'memory_address')
                }"
              >
                <input
                  type="number"
                  class="form-control text-right no-arrows"
                  :value="draftValue(entry.item, 'memory_address')"
                  @input="draftValue(entry.item, 'memory_address', $event)"
                  :disabled="!draftEnabled(entry.item, 'memory_address')"
                />
              </div>
            </div>
          </template>
          <!-- end draft_memory_address -->

          <!-- begin draft_local_storage_identity_number -->
          <template
            v-slot:draft_local_storage_identity_number="entry"
            v-if="!cloneEnabled && editable"
          >
            <div class="column-content" @click.stop>
              <div
                class="form-group form-group-sm"
                :class="{
                  'has-error': draftError(
                    entry.item,
                    'local_storage_identity_number'
                  )
                }"
                style="padding: 0; margin: 0"
              >
                <input
                  type="text"
                  class="form-control text-right"
                  list="scopes"
                  :value="
                    draftValue(entry.item, 'local_storage_identity_number')
                  "
                  @input="
                    draftValue(
                      entry.item,
                      'local_storage_identity_number',
                      $event
                    )
                  "
                  :disabled="
                    !draftEnabled(entry.item, 'local_storage_identity_number')
                  "
                />
              </div>
            </div>
          </template>
          <!-- end draft_local_storage_identity_number -->
          <template v-slot:toggle="entry">
            <ResourceToggle
              :item="entry.item"
              :disabled="!draftEnabled(entry.item)"
            />
          </template>
          <!-- END editable columns -->
          <!-- begin draft_memory_type -->
          <template
            v-slot:draft_memory_type="entry"
            v-if="!cloneEnabled && editable && !busy"
          >
            <div
              class="column-content"
              :class="{
                'has-error': draftError(entry.item, 'memory_type')
              }"
              @click.stop
            >
              <div class="form-group form-group-sm">
                <select
                  v-if="!busy"
                  class="form-control"
                  :value="draftValue(entry.item, 'memory_type')"
                  @input="draftValue(entry.item, 'memory_type', $event)"
                  :disabled="!draftEnabled(entry.item, 'memory_type')"
                >
                  <option
                    v-for="(o, ix) in dataMemoryTypesFiltered(entry.item)"
                    :value="o.id"
                    :key="ix"
                    :selected="o.id == draftValue(entry.item, 'memory_type')"
                  >
                    {{ o.name }}
                    {{
                      entry.item.memory_size > 1 &&
                      draftValue(entry.item, "memory_type") == o.id
                        ? ` [${entry.item.memory_size}]`
                        : ""
                    }}
                  </option>
                </select>
              </div>
              <div
                v-if="draftError(entry.item, 'memory_type')"
                class="error-description"
                :title="
                  `${$t('current_value')}\n${entry.item.memory_type.id} - ${
                    entry.item.memory_type.name
                  }`
                "
              >
                <i class="fa fa-save"></i>
                {{ entry.item.memory_type.name }}
              </div>
            </div>
          </template>
          <!-- end draft_memory_type -->

          <!-- END editable columns -->
          <template v-slot:info="entry">
            <span :title="info(entry.item).title" style="white-space: nowrap">
              {{ info(entry.item).value }}
            </span>
          </template>
        </SearchableTable>
        <Spin v-else></Spin>
        <div
          class="table-form-footer"
          v-if="!cloneEnabled && editable && list && !busy"
        >
          <span
            class="btn btn-default pull-left"
            :disabled="!draftDirty()"
            @click="draftReset()"
          >
            <i class="fa fa-undo"></i> {{ $tc("restore", 1) }}
          </span>
          <button
            class="btn btn-primary pull-right"
            :disabled="isSaving || !draftDirty() || draftError()"
            @click.stop.prevent="save"
          >
            <i :class="isSaving ? 'fa fa-refresh fa-spin' : 'fa fa-save'"></i>
            {{ $tc(isSaving ? "saving" : "save", 1) }}
          </button>
        </div>
      </div>
      <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    </template>
    <ModalImportFile
      :connectorId="connector_id"
      :deviceId="device_id"
      :entity="importEntity"
      :hasBasic="true"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>

<script>
import {isEqual} from "lodash";
import {onlyNumbers} from "@/plugins/utils.js";
import {InlineFormEditor} from "@/components/searchable-table.vue";
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import DataService from "@/services/equipment-data.js";
import AlarmService from "@/services/alarm.js";
import ConnectorService from "@/services/connector.js";
import DeviceService from "@/services/device.js";
import MixinAlert from "@/project/mixin-alert.js";
import MQTTTopicSpan from "@/components/registration/mqtt-topic-span.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";
import Spin from "@/components/spin.vue";

import {
  strParser,
  onKeyDown
} from "@/components/registration/form-common-fields-inline.vue";
import {sourceValueConst} from "@/assets/constants.js";

export default {
  name: "DashboardEditDataPicker",
  mixins: [MixinAlert],
  components: {
    SearchableTable,
    EmptyListAlert,
    ResourceStatistics,
    ModalImportFile,
    MQTTTopicSpan,
    ResourceToggle,
    Spin
  },
  extends: DashboardEditPickerBase,
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: false,
      default: 0
    },
    deviceList: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    showEmptyPanel: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let self = this;

    return {
      entity: "data",
      title: "data_list",
      activeTopic: "",
      editItem: null,
      isSaving: false,
      importEntity: "",
      currentSort: null,
      cid: 0,
      deletedItemsLoaded: false,
      fetchingDeletedItems: false,
      inlineFilter: [
        {
          name: "only_deleted",
          title: "only_deleted",
          value: false,
          filter(value, item) {
            return (
              (value && item.deleted_at != null) ||
              (!value && item.deleted_at == null)
            );
          },
          onclick(item) {
            if (self.deletedItemsLoaded) self.resetDraftErrors();
            else self.fetchDeletedItems();
          }
        }
      ]
    };
  },
  computed: {
    sortDef() {
      if (this.currentSort) return this.currentSort;
      return {
        column: this.cloneEnabled || !this.editable ? "name" : "draft_name",
        asc: true
      };
    },
    dataList() {
      return (
        this.$store.getters["dashboard/dataList"] || []
      ).filter(({clp_id, device}) =>
        this.deviceId
          ? parseInt(device.id) === parseInt(this.deviceId)
          : parseInt(clp_id) === parseInt(this.connectorId)
      );
    },
    fields() {
      return [
        {
          name: "id",
          title: "ID",
          visible: false
        },
        {
          name: "reference_id",
          title: "reference_id",
          visible: false
        },
        {
          name: "device_id",
          title: "device_id",
          visible: false,
          parser: (item) => {
            return item?.device?.id || "";
          }
        },
        {
          name: "refdevid",
          title: "refdevid",
          visible: false,
          parser: (item) => {
            return item?.device?.reference_device_id || "";
          }
        },
        {
          name: "device",
          title: "device",
          parser: (item) => {
            return item?.device?.name || "";
          },
          visible: !this.deviceId
        },
        //== /name
        {
          name: "name",
          title: "data",
          hint: (item) => {
            return item?.identity_embedded_app
              ? `${this.$t("titles.identity_embedded_app")}: ${
                  item?.identity_embedded_app
                }`
              : "";
          },
          visible: this.cloneEnabled || !this.editable
        },
        {
          name: "draft_name",
          title: "name",
          hint: (item) => {
            return item?.identity_embedded_app
              ? `${this.$t("titles.identity_embedded_app")}: ${
                  item?.identity_embedded_app
                }`
              : "";
          },
          visible: !this.cloneEnabled && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "200px"
          },
          parser: (item) => {
            return item?._draft?.fields?.name?.value ?? "";
          }
        },
        //== \name

        //== /source_value
        {
          name: "source_value",
          title: "value_source",
          visible: this.cloneEnabled || (!this.isMQTT && !this.editable),
          parser: (item) => {
            return item.is_local
              ? this.$t("local_data")
              : this.sourceText(item?.source_value?.id);
          }
        },
        {
          name: "draft_source_value",
          title: "value_source",
          visible: !this.cloneEnabled && !this.isMQTT && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "60px",
            "max-width": "110px"
          },
          parser: (item) => {
            return item.is_local
              ? this.$t("local_data")
              : this.sourceText(item?._draft?.fields?.source_value?.value);
          }
        },
        //== \source_value

        //== /memory_address
        {
          name: "memory_address",
          title: "address",
          visible: this.cloneEnabled || (!this.isMQTT && !this.editable),
          style: {
            "text-align": "center"
          },
          parser: function(item) {
            return `${item?.memory_address ?? ""}`.padStart(6);
          }
        },
        {
          name: "draft_memory_address",
          title: "address",
          visible: !this.cloneEnabled && !this.isMQTT && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "60px",
            "max-width": "80px"
          },
          parser: (item) => {
            var vlr = item?._draft?.fields?.memory_address?.value ?? "";
            return `${vlr}`.padStart(6);
          }
        },
        //== \memory_address

        //== /local_storage_identity_number
        {
          name: "local_storage_identity_number",
          title: this.deviceId ? "data_identification" : "ID",
          visible: this.cloneEnabled || (!this.isMQTT && !this.editable),
          style: {
            "text-align": "center"
          },
          parser: function(item) {
            return `${item?.local_storage_identity_number ?? ""}`.padStart(6);
          },
          tooltip: "hints.data_identification"
        },
        {
          name: "draft_local_storage_identity_number",
          title: this.editable ? "ID" : "data_identification",
          visible: !this.cloneEnabled && !this.isMQTT && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "60px",
            "max-width": "80px"
          },
          parser: (item) => {
            var vlr =
              item?._draft?.fields?.local_storage_identity_number?.value ?? "";
            return `${vlr}`.padStart(6);
          },
          tooltip: "hints.data_identification"
        },
        //== \local_storage_identity_number

        //== /memory_type
        {
          name: "memory_type",
          title: "memory_type",
          visible: this.cloneEnabled || !this.editable,
          parser: function(item) {
            let memsize = item.memory_size > 1 ? ` [${item.memory_size}]` : "";
            return `${item?.memory_type?.name || ""}${memsize}`;
          }
        },
        {
          name: "draft_memory_type",
          title: "memory_type",
          visible: !this.cloneEnabled && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "60px",
            "max-width": "180px"
          },
          parser: function(item) {
            return item?.memory_type?.name || "";
          }
        },
        //== \memory_type

        //== /label
        {
          name: "label",
          title: "mqtt_topic",
          visible: this.isMQTT && (this.cloneEnabled || !this.editable),
          parser: (item) => {
            return item.is_local ? "" : item.label;
          }
        },
        {
          name: "draft_label",
          title: "mqtt_topic",
          visible: this.isMQTT && !this.cloneEnabled && this.editable,
          style: {
            position: "relative",
            "text-align": "center",
            "min-width": "60px",
            "max-width": "180px"
          },
          parser: (item) => {
            return item?._draft?.fields?.label?.value ?? "";
          }
        },
        //== \label

        {
          name: "info",
          title: " ",
          sortable: false,
          tooltip: `${this.$t(
            "specific_properties"
          )}:<div class='text-left data-flags'>${this.info()}</div>`,
          style() {
            return {"text-align": "center"};
          }
        },
        {
          name: "toggle",
          title: "enabled",
          visible: () => !this.filteredOnlyDeleted,
          parser: (item) => {
            return `${String.fromCharCode(
              122 + parseInt(item.enabled ? 0 : 1)
            )} ${this.$t(item.enabled ? "yes" : "no")} ${
              item.enabled ? "true" : "false"
            }`;
          },
          style() {
            return {"text-align": "center"};
          }
        },
        {
          name: "deleted_at",
          title: "deleted_at",
          visible: () => this.filteredOnlyDeleted,
          style: {"text-align": "center"},
          hint: (item) => {
            return `${item.id} ${item.name}`;
          },
          format: (value) => {
            return value ? this.$dt.format(value) : "-";
          },
          style() {
            return {"text-align": "center"};
          }
        }
      ];
    },
    commands() {
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: (item) => {
            if (this.device && this.device?.reference_device_id) return false;

            return (
              this.$can("manage", "DadoCadastro") &&
              !this.cloneEnabled &&
              (!this.connector || !this.connector.base_model_id)
            );
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              this.$can("manage", "DadoCadastro") &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              !item.device.connector.base_model_id &&
              this.$can("manage", "DadoCadastro") &&
              !this.cloneEnabled &&
              !item?.device?.reference_device_id
            );
          }
        },
        {
          name: "restore",
          title: "restore",
          icon: "fa fa-undo",
          visible: this.filteredOnlyDeleted,
          enabled: (item) => {
            return (
              this.filteredOnlyDeleted &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro") &&
              this.restoreEnabled(item)
            );
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          visible: !this.filteredOnlyDeleted,
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.nSelected &&
              !item?.device?.reference_device_id &&
              this.removeEnabled(item)
            );
          }
        },
        {name: "-"},
        {
          name: "open",
          title: "alarm_list",
          icon: "fa fa-bell",
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.isFreePlan &&
              !this.nSelected &&
              item.memory_size == 1 &&
              !item.is_local &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "addChild",
          title: this.$t("add_new_alarm"),
          icon: "fa fa-plus",
          enabled: (item) => {
            return (
              !this.filteredOnlyDeleted &&
              !this.isFreePlan &&
              !this.nSelected &&
              item.memory_size == 1 &&
              !item.is_local &&
              this.$can("manage", "AlarmeCadastro") &&
              (item.device.connector.base_model_id ? false : !this.cloneEnabled)
            );
          }
        }
      ];
      if (this.cloneEnabled) return items;
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "newConnector",
          title: "new_connector",
          icon: "fa fa-plus"
        });
      if (!(this.$store.getters["dashboard/connectorList"] || []).length)
        return items;

      if (this.$can("manage", "EstacaoCadastro"))
        items[0].commands.push({
          name: "newDevice",
          title: "new_device",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro"))
        items[0].commands.push({
          name: "newData",
          title: "new_data",
          icon: "fa fa-plus"
        });
      if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
        items[0].commands.push({
          name: "newAlarm",
          title: "new_alarm",
          icon: "fa fa-plus",
          enabled: (item) => {
            return !item.is_local && item.memory_size == 1;
          }
        });
      if (this.$can("manage", "DadoCadastro") && !this.hideImport())
        items[0].commands.push({
          name: "importData",
          title: "import_data",
          icon: "fa fa-plus"
        });
      if (
        !this.isFreePlan &&
        this.$can("manage", "AlarmeCadastro") &&
        !this.hideImport()
      )
        items[0].commands.push({
          name: "importAlarms",
          title: "import_alarms",
          icon: "fa fa-plus",
          enabled: (item) => {
            return !item.is_local;
          }
        });
      return items;
    },
    newItemPath() {
      return `/dashboard/edit/connector/${this.connector_id || 0}/device/${this
        .device_id || 0}/data/0`;
    },
    list() {
      return this.items;
    },
    device() {
      return this?.parent && this.parent.id == this.device_id
        ? this.parent
        : null ||
            (this.$store.getters["dashboard/deviceList"] || []).find(
              ({id}) => id == this.device_id
            );
    },
    dataValueSourceTypes() {
      return this?.$root?.config?.references?.data_value_source_types || [];
    },
    dataMemoryTypes() {
      return this?.$root?.config?.references?.data_memory_types || [];
    },
    hasVirtualDevice() {
      return (this?.items || []).some(
        ({device}) =>
          device?.data_collector_device_id &&
          parseInt(device?.data_collector_device_id) != parseInt(device.id)
      );
    },
    massRemoveEnabled() {
      if (!this.nSelected || this.filteredOnlyDeleted) return false;
      let self = this;
      let removeEnabledList = (this.items || []).filter((item) => {
        return (
          self.multiSelection.values.indexOf(item.id) >= 0 &&
          self.removeEnabled(item)
        );
      });
      return removeEnabledList.length == this.nSelected;
    },
    massRestoreEnabled() {
      if (!this.nSelected || !this.filteredOnlyDeleted) return false;
      let self = this;
      let restoreEnabledList = (this.items || []).filter((item) => {
        return (
          self.multiSelection.values.indexOf(item.id) >= 0 &&
          self.restoreEnabled(item)
        );
      });
      return restoreEnabledList.length == this.nSelected;
    },
    tableClass() {
      if (!this.cloneEnabled && this.editable && this.draftError()) {
        return "table table-condensed table-error table-bordered table-responsive table-hover table-striped";
      } else {
        return "table table-condensed table-bordered table-responsive table-hover table-striped";
      }
    },
    filteredOnlyDeleted() {
      return this.inlineFilter.some(
        (item) => item.name == "only_deleted" && item.value
      );
    },
    filteredItems() {
      const lst =
        (this.filteredOnlyDeleted ? this.discarded : this.existing) || [];
      return lst.filter((i) => this.filterItem(i));
    },
    discarded() {
      const lst = this.items || [];
      return lst.filter(({deleted_at}) => deleted_at);
    },
    recoverablePeriod() {
      return this.$root.config.recoverable_period || 30;
    },
    existing() {
      const lst = this.items || [];
      return lst.filter(({deleted_at}) => !deleted_at);
    },
    nTotal() {
      if (this.filteredOnlyDeleted) return this.discarded.length;
      return this.existing.length;
    },
    nShowing() {
      return !this.maxResult && !this.$refs.stbl
        ? 0
        : this.cid
        ? this?.$refs?.stbl?.itemList?.length || 0
        : this.maxResult;
    },
    msgBoard() {
      let msg = [];
      if (this.filteredOnlyDeleted) {
        msg.push(
          this.$t("hints.cleaning_due_date", {days: this.recoverablePeriod})
        );
      }
      return msg.join(" | ");
    }
  },
  watch: {
    parent(n) {
      if (n) {
        let connector = n?.connector || this?.connector || null;
        let items = [
          {
            name: "connector_plural",
            url: "/dashboard/edit/connector"
          },
          {
            name: connector?.name || "-",
            url: `/dashboard/edit/connector/${connector?.id || 0}`
          }
        ];
        items.push({
          name: "device_plural",
          url: `/dashboard/edit/connector/${connector?.id || 0}/device`
        });
        let deviceId = this.deviceId || 0;
        if (deviceId) {
          items.push({
            name: n.name,
            url: `/dashboard/edit/connector/${connector?.id ||
              0}/device/${deviceId}`
          });
        }
        items.push({
          name: "data_plural",
          url: `/dashboard/edit/connector/${connector?.id ||
            0}/device/${deviceId}/data`
        });
        this.$set(this, "nav", {
          previous: `/dashboard/edit/connector/`,
          items: items
        });
      }
    },
    items(n, o) {
      if (!n || !o || n.length == o.length) return;
      this.$emit("data", n);
    },
    connector_id(n, o) {
      if (o && n && n != o) {
        this.items = null;
        if (this.editable) {
          this.inlineFormEditor = null;
        }
        this.deletedItemsLoaded = false;
        this.fetchItems();
      }
    },
    dataList: {
      handler(n, o) {
        this.buildItems(n);
      },
      immediate: true,
      deep: true
    },
    isEmpty(n, o) {
      if (n) this.fetchDeletedItems();
    },
    busy() {
      this.$nextTick(() => this.cid++);
    }
  },
  methods: {
    buildItems(lst) {
      let n = (lst || [])
        .map(({etag, device}) => `${etag}${device?.name}`)
        .sort();
      let o = (this.items || [])
        .map(({etag, device}) => `${etag}${device?.name}`)
        .sort();
      if (!isEqual(n, o)) {
        let items = JSON.parse(JSON.stringify(lst || []));
        this.busy = true;
        this.inlineFormEditor = null;
        this.onResposeReady(items);
        var first = (this.items || [])[0];
        if (first?.device?.connector)
          this.$set(
            this,
            "parent",
            this.deviceId ? first.device : first.device.connector
          );
        this.$nextTick(() => {
          this.$emit("loading", false);
          this.busy = false;
        });
      }
    },
    removeEnabled(item) {
      return (
        !item.deleted_at &&
        !item.device.connector.base_model_id &&
        this.$can("manage", "DadoCadastro") &&
        !this.cloneEnabled &&
        (!item?.device?.data_collector_device_id ||
          !item?.device?.reference_device_id)
      );
    },
    restoreEnabled(item) {
      return (
        item.deleted_at &&
        !item.device.connector.base_model_id &&
        this.$can("manage", "DadoCadastro") &&
        !this.cloneEnabled &&
        (!item?.device?.data_collector_device_id ||
          !item?.device?.reference_device_id)
      );
    },
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "data",
          this.$tc("n_records_selected", item.length, {
            amount: item.length
          })
        );
      }

      // Single record remove mode
      if (item?.device?.connector?.base_model) {
        let message = this.$t("removal_model_message");
        let field_name = this.$tc("data");
        let value = item.name;
        let warning = `<p>${message}</p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (this.isModel) {
        this.$router.push(
          `/dashboard/edit/connector/${this.connectorId}/device/${this.deviceId}/data/0`
        );
        return;
      }
      this.validateResourceQuota(
        "data_plural",
        this.contract.maximum_data,
        this.contract.registered_data
      ).then((resp) => {
        if (resp == "proceed") {
          this.$router.push(
            `/dashboard/edit/connector/${this.connectorId}/device/${this.deviceId}/data/0`
          );
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    open(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${this
          .device_id ||
          command?.target?.device?.id ||
          0}/data/${command.target.id}/alarm`
      );
    },
    edit(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${this
          .device_id ||
          command?.target?.device?.id ||
          0}/data/${command.target.id}`
      );
    },
    select(command) {
      if (this.editable && this.draftDirty()) {
        this.$utils
          .confirm(this, this.$tc("you_have_unsaved_changes"))
          .then((confirmed) => {
            if (!confirmed) return;
            this.edit(command);
          });
        return;
      }
      this.edit(command);
    },
    clone(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${this
          .device_id ||
          command?.target?.device?.id ||
          0}/data/${command.target.id}?a=c`
      );
    },
    hideImport() {
      return !this.connector || this.connector.base_model_id != null;
    },
    restore(command) {
      if (!command.target) return;
      if (command.target.id > 0) {
        let data = (this.items || []).find(({id}) => id == command.target.id);
        if (data) {
          this.$swal({
            title: this.$t("are_you_sure"),
            content: this.wrap(this.$t("you_really_want_to_restore")),
            icon: "warning",
            buttons: [this.$t("cancel"), this.$t("restore")],
            dangerMode: true
          }).then((ok) => {
            if (ok) {
              let callback = (restored) => {
                if (!restored) return;
                this.$emit("data");
              };
              this.restoreItems(data, callback);
            }
          });
        }
      }
    },
    restoreItems(data, callback) {
      let count = data?.length ? data.length : 1;
      if (count == 0) return;
      let deleted_data_dict = {};
      (data?.length ? data : [data]).forEach((item) => {
        deleted_data_dict[item.id] = {
          deleted_at: item.deleted_at
        };
        item.deleted_at = null;
      });
      let rollbackDeletedAt = (items) => {
        (items?.length ? items : [items]).forEach((item) => {
          item.deleted_at = deleted_data_dict[item.id].deleted_at;
        });
      };

      this.$emit("loading", true);
      this.service
        .save(data)
        .then((ret) => {
          this.$emit("loading", false);
          if (
            (ret && ret instanceof Object && ret.id) ||
            (ret && ret instanceof Array && ret[0].id)
          ) {
            // Restaura o Conector completamente
            this.$store
              .dispatch("dashboard/fetchConnector", {
                id: this.connectorId,
                deep: true
              })
              .then(() => callback(true))
              .catch(() => callback(false));
            // Restaura também as instâncias
            if (this.connector.base_model) {
              (this.$store.getters["dashboard/connectorList"] || [])
                .filter(({base_model_id}) => base_model_id == this.connector.id)
                .forEach((model_instance) => {
                  this.$store.dispatch("dashboard/fetchConnector", {
                    id: model_instance.id,
                    deep: true
                  });
                });
            }

            this.$utils.notifyUser(
              this,
              this.$t("you_have_restored_n_items", {count: count})
            );
          } else {
            rollbackDeletedAt(data);
            this.validateSaveResponse(ret);
            this.showAlert();
          }
        })
        .catch((error) => {
          rollbackDeletedAt(data);
          this.$emit("loading", false);
          this.validateSaveResponse(error);
          this.showAlert();
        });
    },
    remove(command) {
      let config = {
        item: command.target,
        type: "data",
        resource_key: "data_id",
        rule: "DadoEscrita",
        service: this.service
      };
      this.validateAndRemove(config, (resp) => {
        if (!resp) return;
        let lst = (this.$store.getters["dashboard/dataList"] || [])
          .filter(
            ({device, reference_id}) =>
              device.reference_device_id &&
              parseInt(device.reference_device_id) ==
                parseInt(command.target.device.id) &&
              reference_id == command.target.reference_id
          )
          .map(({id}) => ({data_id: id}));
        if (lst.length) {
          this.items = [];
          this.$store.dispatch("dashboard/removeResources", lst);
        }

        this.deletedItemsLoaded = false;
        this.fetchDeletedItems();
      });
    },
    massRemove() {
      if (this.multiSelection.values.length == 0) return;
      let self = this;
      let config = {
        items: this.multiSelection.values,
        type: "data",
        resource_key: "data_id",
        rule: "DadoEscrita",
        service: this.service
      };

      // Armazenar os dispositivos selecionados que são Dispositivo de Referência
      let reference_ids = (this.items || [])
        .filter((item) => {
          if (this.multiSelection.values.indexOf(item.id) < 0) return false;
          if (!item?.device?.is_reference) return false;
          if (!item?.device?.data_collector_device_id) return false;
          return true;
        })
        .map((item) => {
          return item.reference_id;
        });

      let lst = (this.$store.getters["dashboard/dataList"] || [])
        .filter(({device, reference_id}) => {
          return reference_ids.indexOf(reference_id) >= 0 &&
            device?.reference_device_id
            ? true
            : false;
        })
        .map(({id}) => ({data_id: id}));

      let callback = (removed) => {
        if (!removed) return;
        if (lst.length) {
          this.items = [];
          this.$store.dispatch("dashboard/removeResources", lst);
        }
        this.deletedItemsLoaded = false;
        this.fetchDeletedItems();
        self.multiSelection.values = [];
        self.$emit("resource_deleted", "alarm");
      };
      this.validateAndMassRemove(config, callback);
    },
    massRestore() {
      if (this.multiSelection.values.length == 0) return;
      let self = this;
      let lst = (this.items || []).filter(
        ({id, deleted_at}) =>
          deleted_at && this.multiSelection.values.indexOf(id) >= 0
      );
      let callback = (restored) => {
        if (!restored) return;
        self.multiSelection.values = [];
        self.$emit("data");
      };
      this.restoreItems(lst, callback);
    },
    addChild(command) {
      this.$router.push(
        `/dashboard/edit/connector/${this.connectorId}/device/${this.device_id}/data/${command.target.id}/alarm/0`
      );
    },
    filterItem(item) {
      return this.inlineFilter
        .map((option) => !option.value || option.filter(option.value, item))
        .reduce((accumulator, valid) => accumulator && valid);
    },
    fetchItems() {
      let query = {
        contract_id: this.contract.id
      };
      if (this.deviceId) {
        query.device_id = this.deviceId;
      } else if (this.connectorId) {
        query.connector_id = this.connectorId;
      }
      this.fetch(query).then(() => {
        if (this.items && this.items.length) {
          let na, nb;
          this.items.sort((a, b) => {
            na = `${a?.device?.name || ""}_${a.name}`;
            nb = `${b?.device?.name || ""}_${b.name}`;
            return na > nb ? 1 : nb > na ? -1 : 0;
          });
          this.$store.dispatch("dashboard/addResource", {
            resource: "data",
            list: this.items,
            forceUpdate: true
          });
          this.$set(this, "parent", this.items[0].device);
        } else {
          if (this.deviceId) {
            let srv = new DeviceService();
            srv.get(this.deviceId).then((result) => {
              if (result) {
                this.$set(this, "parent", result);
              }
            });
          } else {
            if (this.connectorId && !this.connector) {
              let srv = new ConnectorService();
              srv.get(this.connectorId).then((result) => {
                if (result) {
                  this.$set(this, "parent", result);
                }
              });
            }
          }
        }
      });
    },
    fetchDeletedItems() {
      return new Promise((resolve) => {
        if (this.deletedItemsLoaded) {
          resolve(null);
          return;
        }

        let query = {
          contract_id: this.contract.id,
          connector_id: this.connectorId,
          only_deleted: true
        };
        if (this.device && this.device?.id) {
          query.device_id = this.device.id;
        }

        this.$emit("loading", true);
        this.fetchingDeletedItems = true;
        this.inlineFormEditor = null;
        this.service.fetch(query).then((ret) => {
          this.$emit("loading", false);
          this.fetchingDeletedItems = false;
          if (ret && ret.length) {
            // Montar lista com IDs de Dados já excluídos
            const deleted_ids = ret.map(({id}) => id);
            // Filtrar Items com apenas Dados não excluídos
            let existing_items = (this.items || []).filter(
              ({id, deleted_at}) => !deleted_at && deleted_ids.indexOf(id) < 0
            );
            // Concatenar a nova lista de Dados excluídos com os existentes
            // ignorando Dispositivos excluídos para não confundir
            let items = existing_items.concat(
              ret.filter(({device}) => !device.deleted_at)
            );
            this.$set(this, "items", items);
          }
          this.inlineFormEditor = null;
          if (this.items && this.items.length) this.parseItems(this.items);
          this.resetDraftErrors();
          this.deletedItemsLoaded = true;
          resolve(ret);
        });
      });
    },
    sourceText(qid) {
      return this.dataValueSourceTypes.find(({id}) => id == qid)?.name ?? "";
    },
    parseItems(items) {
      if (!this.editable) return items;
      if (this.inlineFormEditor) return items;
      const validateItems = (items, currentItem) => {
        // reset all errors
        currentItem._draft.fields.name.error = "";
        currentItem._draft.fields.memory_type.error = "";
        currentItem._draft.fields.label.error = "";
        currentItem._draft.fields.memory_address.error = "";
        currentItem._draft.fields.local_storage_identity_number.error = "";

        if (currentItem.deleted_at) return true;
        // source value: address without address
        if (
          !this.isMQTT &&
          !currentItem.is_local &&
          !currentItem._draft.fields.memory_address.error &&
          currentItem?._draft.fields?.source_value?.value ==
            sourceValueConst.ADDRESS &&
          !currentItem?._draft.fields?.memory_address?.value
        ) {
          currentItem._draft.fields.memory_address.error = "invalid_value";
        }

        // source value: local without identity number
        if (
          !this.isMQTT &&
          !currentItem.is_local &&
          !currentItem._draft.fields.local_storage_identity_number.error &&
          currentItem?._draft.fields?.source_value?.value ==
            sourceValueConst.LOCAL &&
          !currentItem?._draft.fields?.local_storage_identity_number?.value
        ) {
          currentItem._draft.fields.local_storage_identity_number.error =
            "invalid_value";
        }

        // label MQTT: without label
        if (
          this.isMQTT &&
          !currentItem._draft.fields.label.error &&
          !currentItem.is_local &&
          !currentItem?._draft?.fields?.label?.value
        ) {
          currentItem._draft.fields.label.error = "invalid_value";
        }

        // memory type: not found
        if (
          !currentItem._draft.fields.memory_type.error &&
          (!currentItem._draft.fields.memory_type.value ||
            !this.dataMemoryTypesFiltered(currentItem).some(
              ({id}) => id == currentItem._draft.fields.memory_type.value
            ))
        ) {
          currentItem._draft.fields.memory_type.error = "invalid_value";
        }

        // name invalid (only numbers)
        if (
          !currentItem._draft.fields.name.error &&
          currentItem?._draft.fields?.name?.value ==
            onlyNumbers(currentItem?._draft.fields?.name?.value)
        ) {
          currentItem._draft.fields.name.error = "invalid_name";
        }

        // validating values with the whole list
        (items || []).forEach((item) => {
          if (item.deleted_at) return true;

          // update attrs of data that belongs to referered virtual devices
          if (
            // is it?
            item?.reference_id == currentItem.reference_id && // same data
            item?.device?.data_collector_device_id && // a virtual device?
            item?.device?.reference_device_id // based on device model?
          ) {
            // update name data
            item._draft.fields.name.value =
              currentItem._draft.fields.name.value;
            item._draft.fields.name.error =
              currentItem._draft.fields.name.error;
            item._draft.fields.source_value.value =
              currentItem._draft.fields.source_value.value;
            item._draft.fields.source_value.error =
              currentItem._draft.fields.source_value.error;
            item._draft.fields.memory_type.value =
              currentItem._draft.fields.memory_type.value;
            item._draft.fields.memory_type.error =
              currentItem._draft.fields.memory_type.error;
            if (this.isMQTT && !currentItem.is_local) {
              item._draft.fields.label.value =
                currentItem._draft.fields.label.value;
              item._draft.fields.label.error =
                currentItem._draft.fields.label.error;
            }
          }

          // Valida Duplicidade do Endereço do Dado
          if (
            // Não valida em caso de:
            // - Mesmo item
            // - Conector MQTT
            // - Dado Local
            // - Não é Origem do Valor: Endereço do Dado
            // - Erro na avaliação do Identificação do Dado na Base Local
            // - Endereço do Dado vazio

            parseInt(item.id) != parseInt(currentItem.id) &&
            !this.isMQTT &&
            !currentItem.is_local &&
            !currentItem._draft.fields.local_storage_identity_number.error &&
            currentItem?._draft.fields?.source_value?.value ==
              sourceValueConst.ADDRESS &&
            currentItem?._draft.fields?.memory_address?.value !== "" &&
            item?._draft.fields?.memory_address?.value !== "" &&
            // Valida apenas se for o mesmo Dispositivo Coletor
            (parseInt(item.device.id) == parseInt(currentItem.device.id) ||
              (item.device.data_collector_device_id &&
                currentItem.device.data_collector_device_id &&
                parseInt(item.device.data_collector_device_id) ==
                  parseInt(currentItem.device.data_collector_device_id)) ||
              (currentItem.device.data_collector_device_id &&
                parseInt(item.device.id) ==
                  parseInt(currentItem.device.data_collector_device_id)) ||
              (item.device.data_collector_device_id &&
                parseInt(item.device.data_collector_device_id) ==
                  parseInt(currentItem.device.id))) &&
            // Não pode repetir Endereço do Dado
            parseInt(currentItem?._draft.fields?.memory_address?.value) ==
              parseInt(item?._draft.fields?.memory_address?.value) &&
            // Junto com Tipo de Memória
            parseInt(currentItem?._draft.fields?.memory_type?.value) ==
              parseInt(item?._draft.fields?.memory_type?.value) &&
            // Junto com Origem do Valor
            parseInt(currentItem?._draft.fields?.source_value?.value) ==
              parseInt(item?._draft.fields?.source_value?.value) &&
            // Junto com Vetor / Não Vetor
            ((parseInt(currentItem?.memory_size) == 1 &&
              parseInt(item.memory_size) == 1) ||
              (parseInt(currentItem?.memory_size) > 1 &&
                parseInt(item.memory_size) > 1)) &&
            // Junto com Tipo de Cálculo
            parseInt(currentItem?.calculation_type?.id || 0) === 0 &&
            parseInt(item?.calculation_type?.id || 0) === 0
          ) {
            currentItem._draft.fields.memory_address.error =
              "duplicated " + item.device.name + " " + item.name;
            item._draft.fields.memory_address.error =
              "duplicated " + item.device.name + " " + item.name;
          }

          // Valida Duplicidade da Identificação do Dado na Base Local
          if (
            // Não valida em caso de:
            // - Mesmo item
            // - Conector MQTT
            // - Dado Local
            // - Não é Origem do Valor: Base Local do CLP
            // - Identificação do Dado na Base Local vazio
            parseInt(item.id) != parseInt(currentItem.id) &&
            !this.isMQTT &&
            !currentItem.is_local &&
            !currentItem._draft.fields.local_storage_identity_number.error &&
            currentItem?._draft.fields?.source_value?.value ==
              sourceValueConst.LOCAL &&
            // Valida apenas se for o mesmo Dispositivo Coletor
            (parseInt(item.device.id) == parseInt(currentItem.device.id) ||
              (item.device.data_collector_device_id &&
                currentItem.device.data_collector_device_id &&
                parseInt(item.device.data_collector_device_id) ==
                  parseInt(currentItem.device.data_collector_device_id)) ||
              (currentItem.device.data_collector_device_id &&
                parseInt(item.device.id) ==
                  parseInt(currentItem.device.data_collector_device_id)) ||
              (item.device.data_collector_device_id &&
                parseInt(item.device.data_collector_device_id) ==
                  parseInt(currentItem.device.id))) &&
            // Não pode repetir Identificação do Dado na Base Local
            currentItem?._draft.fields?.local_storage_identity_number?.value !==
              "" &&
            item?._draft.fields?.local_storage_identity_number?.value !== "" &&
            parseInt(
              currentItem?._draft.fields?.local_storage_identity_number?.value
            ) ==
              parseInt(
                item?._draft.fields?.local_storage_identity_number?.value
              ) &&
            // Junto com Tipo de Memória
            parseInt(currentItem?._draft.fields?.memory_type?.value) ==
              parseInt(item?._draft.fields?.memory_type?.value) &&
            // Junto com Origem do Valor
            parseInt(currentItem?._draft.fields?.source_value?.value) ==
              parseInt(item?._draft.fields?.source_value?.value) &&
            // Junto com Tipo de Cálculo
            parseInt(currentItem?.calculation_type?.id || 0) === 0 &&
            parseInt(item?.calculation_type?.id || 0) === 0
          ) {
            currentItem._draft.fields.local_storage_identity_number.error =
              "duplicated";
            item._draft.fields.local_storage_identity_number.error =
              "duplicated";
          }

          // From now on, validates the ones within the same device
          if (parseInt(item.device?.id) != parseInt(currentItem.device.id))
            return;

          // name duplicated
          if (
            parseInt(item.id) != parseInt(currentItem.id) &&
            !currentItem._draft.fields.name.error &&
            currentItem?._draft.fields?.name?.value !== "" &&
            currentItem?._draft.fields?.name?.value ==
              item?._draft.fields?.name?.value
          ) {
            currentItem._draft.fields.name.error = "duplicated";
            item._draft.fields.name.error = "duplicated";
          }

          // label MQTT: duplicated
          if (
            parseInt(item.id) != parseInt(currentItem.id) &&
            !currentItem._draft.fields.label.error &&
            this.isMQTT &&
            !currentItem.is_local &&
            currentItem._draft.fields.label.value != "" &&
            currentItem._draft.fields.label.value ==
              item?._draft.fields?.label?.value
          ) {
            currentItem._draft.fields.label.error = "duplicated";
            item._draft.fields.label.error = "duplicated";
          }
        });
      };
      this.inlineFormEditor = new InlineFormEditor(items, (item) => ({
        name: {
          item_attr: "name",
          value: `${item?.name}`,
          onchange: (attr, value, item) => {
            if (value !== "") {
              if (
                !item.is_local &&
                (item._draft.fields.label.value === "" ||
                  this.$utils.asLabel(item._draft.fields.name.old) ===
                    item._draft.fields.label.value)
              ) {
                item._draft.fields.label.value = this.$utils.asLabel(value);
              }
            }
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.name.previous = item._draft?.fields.name.value;
            item.name = item._draft.fields.name.value;
          }
        },
        label: {
          item_attr: "label",
          value: `${item?.label}`,
          onchange: (attr, value, item) => {
            item._draft.fields.label.value = this.$utils.asLabel(
              item._draft.fields.label.value
            );
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.label.previous = item._draft?.fields.label.value;
            item.label = item._draft.fields.label.value;
          }
        },
        memory_address: {
          item_attr: "memory_address",
          value: `${item?.memory_address ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.memory_address.previous =
              item._draft?.fields.memory_address.value;
            item.memory_address = parseInt(
              item._draft.fields.memory_address.value
            );
          }
        },
        source_value: {
          item_attr: "source_value_id",
          value: `${item?.source_value?.id}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            if (value == sourceValueConst.ADDRESS) {
              item._draft.fields.local_storage_identity_number.value = null;
            }
            // item._draft?.fields.local_storage_identity_number.value
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.source_value.previous =
              item._draft?.fields.source_value.value;
            item.source_value.id = parseInt(
              item._draft.fields.source_value.value
            );
          }
        },
        local_storage_identity_number: {
          item_attr: "local_storage_identity_number",
          value: `${item?.local_storage_identity_number ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.local_storage_identity_number.previous =
              item._draft?.fields.local_storage_identity_number.value;
            item.local_storage_identity_number = parseInt(
              item._draft.fields.local_storage_identity_number.value
            );
          }
        },
        memory_type: {
          item_attr: "memory_type_id",
          value: `${item?.memory_type?.id ?? ""}`,
          onchange: (attr, value, item) => {
            // console.log(value);
            validateItems(items, item);
            items.forEach((recheckItem) => validateItems(items, recheckItem));
          },
          save: (item) => {
            item._draft.fields.memory_type.previous =
              item._draft?.fields.memory_type.value;
            item.memory_type.id = parseInt(
              item._draft.fields.memory_type.value
            );
          }
        }
      }));
      items.forEach((item) => {
        this.inlineFormEditor.prop(item, "memory_type", item.memory_type.id);
      });
      return items;
    },
    draftValue(item, attr, $event) {
      if (!this.inlineFormEditor) return;
      if (attr == "source_value" && item.is_local) return "";
      item._draft.fields[attr].old = item._draft.fields[attr].value;
      return this.inlineFormEditor.prop(
        item,
        attr,
        $event ? strParser($event.target.value) : undefined
      );
    },
    draftReset(item, attr, $event) {
      if (!this.inlineFormEditor || ($event && !this.draftEnabled(item, attr)))
        return;
      this.inlineFormEditor.reset(item, attr);
      let $el =
        $event && $event.target.parentElement.getElementsByTagName("input")[0];
      if ($el) {
        $el.focus();
        $el.select();
      }
    },
    draftError(item, attr) {
      if (!this.inlineFormEditor || this.filteredOnlyDeleted) return;
      return this.inlineFormEditor.error(item, attr);
    },
    draftDirty(item, attr) {
      if (!this.inlineFormEditor) return false;
      return this.inlineFormEditor.isDirty(item, attr);
    },
    draftEnabled(item, attr) {
      if (this.filteredOnlyDeleted) return false;

      switch (attr) {
        case "name":
          return (
            !item.device.reference_device_id ||
            parseInt(item.device.reference_device_id) ==
              parseInt(item.device.id)
          );

        case "label":
          if (item.is_local) return false;
          return (
            !item.device.reference_device_id ||
            parseInt(item.device.reference_device_id) ==
              parseInt(item.device.id)
          );

        case "source_value":
          if (item.is_local) return false;
          return (
            !item.device.reference_device_id ||
            parseInt(item.device.reference_device_id) ==
              parseInt(item.device.id)
          );

        case "memory_type":
          if (item.is_local) return false;
          return (
            !item.device.reference_device_id ||
            parseInt(item.device.reference_device_id) ==
              parseInt(item.device.id)
          );

        case "memory_address":
          if (item.is_local) return false;
          if (
            item._draft?.fields["source_value"].value ==
              sourceValueConst.ADDRESS ||
            !item.read_only
          )
            return true;
          return false;

        case "local_storage_identity_number":
          if (item.is_local) return false;
          if (
            item._draft?.fields["source_value"].value == sourceValueConst.LOCAL
          )
            return true;
          return false;

        default:
          return true;
      }
    },
    draftItem(item) {
      return {
        ...item,
        name: item._draft.fields.name.value,
        label: item._draft.fields.label.value,
        memory_type: {
          id: item._draft.fields.memory_type.value
        },
        source_value: {
          id: item._draft.fields.source_value.value
        }
      };
    },
    resetDraftErrors() {
      (this.items || []).forEach(({_draft}) => {
        _draft &&
          _draft.fields &&
          Object.values(_draft.fields).forEach((f) => (f.error = ""));
      });
    },
    save() {
      if (this.isSaving || !this.draftDirty() || this.draftError()) return;
      let payload = (this?.items || [])
        ?.filter((i) => (i.id && i.etag && this.draftDirty(i)) || false)
        .map((i) => {
          let entry = null;
          for (var p in i._draft?.fields) {
            if (this.draftEnabled(i, p) && this.draftDirty(i, p)) {
              entry = entry || {id: i.id, etag: i.etag};
              entry[i._draft?.fields[p].item_attr] = i._draft.fields[p].value;
            }
          }
          return entry;
        })
        .filter((i) => i != null);
      if (!payload.length) return;
      this.$set(this, "isSaving", true);
      this.service
        .save(payload)
        .then((ret) => {
          this.$set(this, "isSaving", false);
          if (ret && ret.length == payload.length && ret[0].id) {
            if (this.validateSaveResponse(ret[0])) {
              let txt = this.$t("you_have_saved_n_items", {
                count: ret.length
              });
              this.alert = {
                title: this.$t("save"),
                text: txt,
                html: txt,
                type: "success"
              };
              (ret || []).forEach((item) => {
                let localItem =
                  (this.items || []).find(({id}) => id == item.id) || null;
                let sentItem = payload.find(({id}) => id == item.id) || null;
                if (localItem && sentItem) {
                  localItem.etag = item.etag;
                  for (var p in localItem._draft?.fields) {
                    // localItem._draft.fields[p].previous =
                    //   localItem._draft?.fields[p].value;
                    localItem._draft.fields[p].save(localItem);
                  }
                  (this.items || []).forEach((i) => {
                    // update attrs of data that belongs to referered virtual devices
                    if (
                      // is it?
                      i?.reference_id == localItem.reference_id && // the same data
                      i?.device?.data_collector_device_id && // a virtual device?
                      i?.device?.reference_device_id // based on device model?
                    ) {
                      i.name = localItem.name;
                      i.label = localItem.label;
                      i.memory_type = {...localItem.memory_type};
                      i.source_value = {...localItem.source_value};
                      i._draft.fields["name"].save(i);
                      i._draft.fields["source_value"].save(i);
                      i._draft.fields["memory_type"].save(i);
                    }
                  });
                }
              });
              this.$store.dispatch("dashboard/setDataValue", this.items);
              this.$emit("data", this.items);
              this.showAlert();
              return;
            }
          }
          this.validateSaveResponse(ret);
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    log() {
      if (!this.connector_id) return;
      console.table(
        Object.fromEntries(
          this.$store.getters["dashboard/dataList"]
            .filter(
              ({clp_id}) => parseInt(clp_id) == parseInt(this.connector_id)
            )
            .map(({id, device, reference_id, name}) => ({
              id,
              device: device.name,
              reference_id,
              name,
              i: `${device.name}/${name}`.toUpperCase()
            }))
            .sort((a, b) => (a.i > b.i ? 1 : b.i > a.i ? -1 : 0))
            .map(({id, device, reference_id, name}) => [
              id,
              {device, reference_id, name}
            ])
        )
      );
    },
    info(item) {
      if (item) {
        var lst = [
          [
            ["r", this.$t("access_mode.read_only")],
            ["r w", this.$t("access_mode.read_write")],
            ["w", this.$t("access_mode.write_only")]
          ][item.read_write_mode]
        ];
        item.history_enabled && lst.push(["h", this.$t("history")]);
        item.is_local && lst.push(["l", this.$t("titles.local_data")]);
        item.allowed_mapping_value && lst.push(["m", this.$t("mapping")]);
        return {
          value: lst.map((i) => i[0]).join(" "),
          title: `${lst.map((i) => i[1]).join(" + ")}`
        };
      } else {
        return [
          {value: "r", title: this.$t("access_mode.read_only")},
          {value: "w", title: this.$t("access_mode.write_only")},
          {value: "h", title: this.$t("history")},
          {value: "l", title: this.$t("titles.local_data")},
          {value: "m", title: this.$t("mapping")}
        ]
          .map((i) => `<div>${i.value} = ${i.title}</div>`)
          .join("");
      }
    },
    deviceById(deviceId) {
      return (
        (deviceId &&
          this.$store.getters["dashboard/deviceList"].find(
            ({id}) => parseInt(id) == parseInt(deviceId)
          )) ||
        null
      );
    },
    dataMemoryTypesFiltered(item) {
      let protocol_id =
        item?.device?.connector?.protocol.id || this?.connector?.protocol?.id;
      return (this.dataMemoryTypes || []).filter((i) => {
        let lst = i.allowed_protocols.filter((p) => p.id == protocol_id);
        return (
          ((item.is_local && i.allows_use_in_local_data) ||
            (!item.is_local && lst.length > 0)) &&
          (item.memory_size > 1 ? i.vector_max_size > 1 : true)
        );
      });
    },
    dataValueSourceTypesFiltered(item) {
      return item.is_local
        ? [{id: "", name: this.$t("local_data")}]
        : this.isFreePlan ||
          (item && item.device && !item.device.local_storage_enabled)
        ? this.dataValueSourceTypes.filter(
            ({number}) => number != sourceValueConst.LOCAL
          )
        : this.dataValueSourceTypes;
    },
    strParser: strParser,
    onKeyDown: onKeyDown
  },
  beforeCreate() {
    this.service = new DataService();
    this.alarmService = new AlarmService();
    this.$emit("rule", "DadoAcesso");
  },
  created() {
    if (this.dataList.length == 0) this.fetchItems();
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 0 0 30px 0;
  padding: 0 15px 50px 15px;
}

.custom-filters {
  min-width: 100%;
  width: 100%;
  margin: 15px 0px -15px;
  white-space: nowrap;
}
.filter-option {
  margin-right: 15px;
}
.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}
.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.searchable-table {
  padding-bottom: 100px;
}

.alert-default a {
  text-decoration: none;
}

.alert-default {
  background-color: whitesmoke;
  color: #555;
  text-shadow: #bbb 0px 1px;
  text-align: center;
}

.msg-board {
  position: absolute;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  margin-left: -15px;
}

.column-content {
  pointer-events: auto;
  position: relative;
}

.column-content > .form-group {
  padding: 0;
  margin: 0;
  min-width: 70px;
}

.column-content > .form-group .form-control {
  padding: 0 5px 0 0;
}

.table-form-footer {
  margin: 0 -10px 0 -10px;
  padding: 20px;
  border-top: 1px solid #adadad;
}

::v-deep .input-prefix-label {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2px;
}
.column-content.has-error > .error-description {
  position: absolute;
  bottom: -18px;
  font-size: 72%;
  text-align: left;
  width: 100%;
  color: rgb(129 80 80);
  padding-left: 2px;
}

.column-content.has-error > .error-description:hover {
  color: rgb(139 7 7);
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  #export {
    margin-left: -32px;
  }
}
</style>

<style>
.tooltip-inner > .data-flags {
  font-family: "Courier New", Courier, monospace;
}

.input-prefix-label {
  padding-right: 0px;
  font-weight: 600;
  color: #999;
  background-color: #f9f9f9 !important;
}
</style>
